html {
  height: 100%;
}

body {
  height: 100%;
}

.d-none {
  display: none !important;
}

#wrap--full {
  width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;

  #wrap--content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
}

img {
  display: block;
  @include img-fluid;
}

.page-content {
  &.page-content__row {
    display: grid;

    &.page-content__row--one_column {
      grid-template-columns: 1fr;
    }

    &.page-content__row--two_column {
      grid-template-columns: 1fr 1fr;
    }

    &.page-content__row--three_column {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.page-content__row--sidebar_left {
      grid-template-columns: 1fr 3fr;
    }

    &.page-content__row--sidebar_right {
      grid-template-columns: 3fr 1fr;
    }
  }
}

// creating custom placeholders that act the same as h1 and .h1
// but avoid problems with extending classes

%oh-h1 {
  @extend %heading;
  @include font-size($h1-font-size);
}

%oh-h2 {
  @extend %heading;
  @include font-size($h2-font-size);
}

%oh-h3 {
  @extend %heading;
  @include font-size($h3-font-size);
}

%oh-h4 {
  @extend %heading;
  @include font-size($h4-font-size);
}

%oh-h5 {
  @extend %heading;
  @include font-size($h5-font-size);
}

%oh-h6 {
  @extend %heading;
  @include font-size($h6-font-size);
}

// custom heading styles that apply to all headings
%oh-heading {
  text-transform: none;
  font-weight: 700;
}

// custom heading styles for specific headings

h1,
.h1,
%oh-h1 {
  @extend %oh-heading;
}

h2,
.h2,
%oh-h2 {
  @extend %oh-heading;
}

h3,
.h3,
%oh-h3 {
  @extend %oh-heading;
}

h4,
.h4,
%oh-h4 {
  @extend %oh-heading;
}

h5,
.h5,
%oh-h5 {
  @extend %oh-heading;
}

h6,
.h6,
%oh-h6 {
  @extend %oh-heading;
}

p {
  min-height: $line-height-base + em;
}

a {
  transition: $btn-transition;

  &.link-external {
    &::after {
      @include fa-pseudo-solid('\f08e');
      margin-left: 0.25em;
    }
  }

  &[href$='.pdf'],
  &.link-pdf {
    &::after {
      @include fa-pseudo-solid('\f1c1');
      margin-left: 0.25em;
    }
  }
}

a.btn {
  letter-spacing: 0.016em;
}

@mixin oh-bg-primary {
  background-color: $primary;
  color: $white;

  h2 {
    color: $white;
  }

  a:not(.btn) {
    color: white;

    &:hover {
      color: var(--bs-oh-teal);
    }
  }

  a,
  button,
  input,
  select,
  textarea,
  .form-select {
    &:focus,
    &:focus-visible {
      box-shadow: 0 0 0 0.25rem rgba(#50d3b4, 0.5);
    }
  }

  a.btn.btn-primary {
    @extend .btn-oh-teal;
  }
}

.link-unstyled,
.link-unstyled:link {
  color: inherit;
  text-decoration: inherit;
  transition: 0.3s ease;

  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}

.main-header {
  .header-inner {
    @include pulsar-container($oh-container-width-large);

    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }

  @include media-breakpoint-down(lg) {
    position: relative;

    .header-inner {
      position: relative;
      justify-content: center;
      padding: 1rem 4rem;
    }

    .header-logo {
      max-width: 179px;
    }

    .header-right {
      display: none;
    }

    .header-nav {
      position: absolute;
      right: 12px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      background: transparent;
    }
  }

  .header-run-regina-cta {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    color: var(--bs-primary);

    a.btn {
      margin-left: 0.75rem;
    }
  }
}

.header-nav {
  @include oh-bg-primary();

  .navbar-nav {
    @extend .list-unstyled;

    .nav-link {
      @extend .link-unstyled;
      text-decoration: none !important;
      line-height: normal;
      white-space: nowrap;
      padding: 0.25em 0 !important;
      border-bottom: 1px solid transparent;
      margin: 0.75rem 0;

      &:hover,
      &:active,
      &.active {
        color: $white;
        border-color: var(--bs-oh-teal);
      }
    }
  }

  .nav-search-link {
    font-size: 21px;
  }

  .navbar-toggler {
    padding: 0.25rem;
    background-color: $primary;
  }

  .dropdown-menu {
    a {
      color: $body-color;

      &:hover,
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .offcanvas {
      --bs-offcanvas-bg: #{$primary};
      --bs-offcanvas-color: #{$white}; // if necessary
    }

    .navbar {
      @include pulsar-container($oh-container-width-large);
    }

    .navbar-nav {
      gap: 1.5rem;

      .nav-item {
        &:last-of-type {
          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }

    .offcanvas-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
    }
  }

  @include media-breakpoint-down(lg) {
    .nav-search-link {
      display: none;
    }
    .navbar-nav {
      .nav-link {
        color: $body-color !important;
      }
    }

    .dropdown-menu {
      border: none;
      padding: 0;

      a {
        padding: 0.5rem 0.75rem;
        border-radius: $border-radius;
        margin-bottom: 3px;
      }
    }
  }
}

.main-footer {
  @include oh-bg-primary();
  padding-top: 4rem;

  .main-footer-top,
  .main-footer-middle {
    @include pulsar-container($oh-container-width-large);
  }

  .main-footer-top {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    margin-bottom: 3rem;

    > div {
      flex: 1 auto;
    }
  }

  .main-footer-middle,
  .main-footer-bottom {
    @include font-size(13px);
  }

  .main-footer-middle {
    margin-bottom: 3rem;
    line-height: 1.692em;
  }

  .main-footer-bottom {
    padding: 1.5rem 0;
    background-color: $gray-900;

    .main-footer-bottom-inner {
      @include pulsar-container($oh-container-width-large);
      text-align: center;

      p {
        margin: 0;
      }
    }
  }

  .footer-address {
    white-space: nowrap;
  }

  .footer-address,
  .footer-social {
    h2 {
      @extend %oh-h6;

      color: var(--bs-oh-teal);
    }
  }

  .footer-links {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    a {
      white-space: nowrap;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 2rem;

    .footer-logo {
      max-width: 170px;
    }

    .main-footer-top {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 2rem;
    }

    .main-footer-middle {
      margin-bottom: 2rem;
    }

    .main-footer-bottom {
      padding: 1rem 0;
    }

    .footer-links {
      width: 100%;
      max-width: 340px;
    }
  }
}

.btn-oh-teal {
  color: $primary;
}

.btn-oh-white {
  color: $primary;
}

.btn-pdf {
  position: relative;
  padding-right: 4rem;

  &:after {
    @include fa-pseudo-solid('\f1c1');
    @include font-size(24px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
  }
}

.btn-arrow {
  position: relative;
  padding-right: 3rem;

  &:after {
    @include fa-pseudo-solid('\f061');
    @include font-size(21px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.25rem;
  }
}

.btn-back {
  position: relative;
  padding-left: 4rem;

  &:before {
    @include fa-pseudo-solid('\f060');
    @include font-size(24px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.5rem;
  }
}

.link-back {
  position: relative;
  padding-left: 2.25rem;
  font-weight: bold;
  text-decoration: none;

  &:before {
    @include fa-pseudo-solid('\f060');
    @include font-size(24px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}

.blockquote {
  @extend %oh-h3;
  margin: 3rem auto;
  max-width: $oh-container-width-small;
}

blockquote {
  @extend .blockquote;
}

table {
  @extend .table;
}

.oh-fancybox-div {
  display: none;
}

.mb-3 {
  margin-bottom: $spacer;
}

.col-form-label {
  @extend %oh-h2;
}

form {
  .form-label.required {
    &:after {
      content: '\00a0\002A';
    }
  }
}

@import '~nice-select2/dist/css/nice-select2.css';

.nice-select {
  float: none;
  font-size: inherit;
  height: auto;
  @extend .form-select;

  &.open,
  &:active,
  &:focus {
    // copy/paste of .form-control &:focus styles from Bootstrap
    // because pseudo selectors cannot be extended
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }

  &::after {
    display: none;
  }

  .nice-select-dropdown {
    right: 0;
  }

  .list {
    padding: 5px 10px;
  }
}

.alert-bar {
  @include oh-bg-primary();
  margin-bottom: 0;
  border-radius: 0;
  text-align: center;
  padding-left: 3rem;
  padding-right: 3rem;

  .alert-bar-content {
    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-close {
    filter: var(--bs-btn-close-white-filter);
  }
}

.form-group {
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem 1.25rem;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 24px;
}

.accordion-button {
  font-weight: bold;
}

#timer-countdown {
  .timer-active {
    max-width: 350px;
  }

  .timer-message {
    @include font-size(13px);
    margin-bottom: 3px;
    color: $gray-600;

    p {
      margin: 0;
    }
  }

  .timer-countdown--counters {
    display: flex;
    gap: 6px;
  }

  .timer-counter {
    .timer-counter--numbers {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 64px;
      height: 64px;

      @include font-size(40px);
      line-height: 1em;

      background: $gray-100;
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.45);
      border-radius: 4px;

      font-weight: bold;
      color: $primary;

      z-index: 1;

      // make it monospaced
      font-variant-numeric: tabular-nums lining-nums;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        height: 1px;
        width: 100%;
        background-color: $gray-300;
        z-index: -1;
      }
    }

    .timer-counter--label {
      @include font-size(13px);
      color: $gray-600;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .timer-inactive {
    max-width: 330px;
    text-align: right;

    p {
      @extend %oh-h5;

      color: $primary;
    }
  }
}

#search {
  margin-bottom: 0.5rem;

  .search-refine {
    display: flex;
    align-items: flex-start;
    gap: 1rem;

    input {
      width: 100%;
    }
  }

  .search-input-wrapper {
    width: 100%;
    max-width: 580px;
  }
}

#search-results {
  .gsc-control-cse {
    padding: 0;
  }

  .gsc-above-wrapper-area {
    border-bottom: none;
  }
}

@mixin global-card-item-styles {
  @extend .card;

  .card-body {
    display: flex;
    flex-direction: column;
  }

  .card-title {
    @extend %oh-h5;

    &.card-title__news__news {
      margin-bottom: 0;
    }

    a {
      @extend .link-unstyled;
    }
  }

  .card-author {
    font-size: 0.9rem;
  }

  .card-date {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }

  .card-btn {
    margin-top: auto;

    p {
      margin: 0;
    }
  }

  .card-img-top {
    aspect-ratio: 3/2;
    object-fit: cover;
    object-position: center;
    border-radius: $border-radius $border-radius 0 0;
  }
}

.news-listing {
  @include oh-tiles-grid(3);
  margin-bottom: 2rem;

  .news-listing-item {
    @include global-card-item-styles();
  }
}

.news-post-full {
  .news-title {
    margin-bottom: 0.5rem;
  }

  .news-author-date {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .news-img {
    aspect-ratio: 3/2;
    object-fit: cover;
    object-position: center;
    width: 100%;
    margin-bottom: 1.25rem;
  }

  .news-back {
    margin-top: 2rem;

    a {
      font-weight: 700;
      text-decoration: none;

      &:before {
        @include fa-pseudo-solid('\f053');
      }
    }
  }
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.two-col {
  @include oh-tiles-grid(2);

  @include media-breakpoint-up(lg) {
    &.two-col--sidebar-left {
      grid-template-columns: 1fr 2fr;
    }

    &.two-col--sidebar-right {
      grid-template-columns: 2fr 1fr;
    }
  }
}
