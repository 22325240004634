.home-banner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 9 / 4;
  padding-top: 3rem;
  padding-bottom: 3rem;

  .home-banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(270deg, #7ce6d2 0%, #3d376a 38%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.3;
    }
  }

  .home-banner-inner {
    @include pulsar-container($oh-container-width-large);
    position: relative;
    z-index: 2;

    h2 {
      @extend .display-4;

      color: $white;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      font-weight: 700;
      letter-spacing: 0;
    }
  }

  .home-banner-copy {
    @include oh-bg-primary();
    background: none;
    max-width: 600px;
    margin-bottom: 1.5rem;
  }

  .home-banner-ctas {
    .page-content {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    a {
      @extend .btn;
      @extend .btn-lg;
      font-weight: bold;
    }

    p {
      a {
        @extend .btn-oh-white;
      }

      &:first-of-type {
        a {
          @extend .btn-oh-teal;
        }
      }
    }
  }
}

.home-forms {
  position: relative;
  background-color: var(--bs-oh-teal);

  .home-forms-inner {
    position: relative;
    z-index: 1;
  }

  .book-your-stay,
  .email-subscribe {
    padding: 3rem 0;

    input:not([type='submit']) {
      @extend .form-control;
      @extend .form-control-lg;

      margin-bottom: 0.75rem;
    }

    * {
      float: none !important;
    }

    input[type='submit'],
    #jrs-button-1361 {
      margin-top: 0.5rem !important;
    }
  }

  .book-your-stay {
    @include oh-bg-primary();
    background-color: transparent;

    #jrs-widget-1361 {
      margin: 0;
    }

    h2,
    #jrs-title-1361 span {
      @extend %oh-h2;
      color: var(--bs-oh-teal);
    }

    #jrs-title-1361 {
      margin: 0;
      margin-bottom: 1rem;

      span {
        &::before {
          content: '';
          display: none;
        }
      }
    }

    #jrs-calendar-start-1361 {
      width: auto;
    }

    #jrs-submit-1361 {
      margin: 0;

      button {
        @extend .btn;
        @extend .btn-oh-white;

        background-image: none;
        text-transform: none;
      }
    }

    #jrs-calendar-end-1361 {
      width: auto;
    }

    .jrs-calendar-icon-1361 {
      &::after {
        color: var(--bs-oh-teal) !important;
        top: 50%;
        bottom: unset;
        transform: translateY(-50%);
      }
    }
  }

  .email-subscribe {
    h2 {
      color: $primary;
      margin-bottom: 1rem;
      max-width: 340px;
    }

    input[type='submit'] {
      @extend .btn;
      @extend .btn-primary;
      width: auto;
    }
  }

  @include media-breakpoint-up(md) {
    &:before {
      content: '';
      position: absolute;
      background-image: linear-gradient(180deg, #282445 0%, #3d376a 100%);
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .book-your-stay-inner,
    .email-subscribe-inner {
      @include pulsar-container(400px);
      padding: 0;
    }

    .book-your-stay-inner {
      margin-left: 0;
      margin-right: auto;
    }

    .email-subscribe-inner {
      margin-left: auto;
      margin-right: 0;
    }

    .home-forms-inner {
      @include pulsar-container($oh-container-width-large);

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 4rem;
    }
  }

  @include media-breakpoint-down(md) {
    .book-your-stay {
      background-image: linear-gradient(180deg, #282445 0%, #3d376a 100%);
    }

    .book-your-stay-inner,
    .email-subscribe-inner {
      @include pulsar-container($oh-container-width-large);
    }

    .email-subscribe {
      h2 {
        max-width: 800px;
      }
    }
  }
}

.explore {
  padding: 4rem 0;

  .explore-inner {
    @include pulsar-container($oh-container-width-large);

    > h2 {
      margin-bottom: 1.25rem;
    }
  }

  .explore-tiles {
    @include oh-tiles-grid(3);

    .explore-item {
      @include global-card-item-styles();
    }
  }
}

.certified {
  @include oh-bg-primary();
  background-image: linear-gradient(180deg, #282445 0%, #3d376a 73%);
  padding: 4rem 0;

  .certified-inner {
    @include pulsar-container($oh-container-width-large);

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .certified-copy {
    max-width: 348px;
  }

  @include media-breakpoint-down(md) {
    .certified-inner {
      flex-direction: column;
    }
  }
}

.sponsors-slider-section {
  .sponsors-slider-section-inner {
    @include pulsar-container($oh-container-width-large);
    text-align: center;

    h2 {
      margin-bottom: 1.75rem;
    }
  }

  .sponsors-slider-wrapper {
    position: relative;
    @include pulsar-container($oh-container-width-large);
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    background-color: $gray-300;
    border-radius: 1000px;
    width: 50px;
    height: 50px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;

    &::after {
      font-size: 22px !important;
      color: $gray-900;
    }

    &:hover {
      background-color: $gray-200;
    }
  }

  .swiper-button-prev {
    left: -4rem;

    &::after {
      @include fa-pseudo-solid('\f053');
    }
  }

  .swiper-button-next {
    right: -4rem;

    &::after {
      @include fa-pseudo-solid('\f054');
    }
  }

  .sponsors-slider {
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 4rem 5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 4rem 0;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}
