.inner-feature-banner {
  position: relative;
  padding: 2.25rem 0;
  color: $white;
  aspect-ratio: 1366 / 225;
  display: flex;
  align-items: center;
  min-height: 200px;
  max-height: 300px;
  width: 100%;
  background-image: linear-gradient(269deg, #7ce6d2 0%, #3d376a 46%);

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    object-fit: cover;
  }

  .inner-feature-banner-inner {
    @include pulsar-container($oh-container-width-large);

    position: relative;
    z-index: 1;

    h1 {
      position: relative;
      margin: 0;
    }
  }
}

.content-wrap {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 4rem;

  &.width--large {
    @include pulsar-container($oh-container-width-large);
  }

  &.width--medium {
    @include pulsar-container($oh-container-width-medium);
  }

  &.width--small {
    @include pulsar-container($oh-container-width-small);
  }
}
