// ----- Pseudo-element Font Awesome Icons
// Helper mixin, no need to use this one
@mixin fa-pseudo($font-weight, $content) {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: $font-weight;
  content: $content;
}

// Use for solid icons
@mixin fa-pseudo-solid($content) {
  font: var(--fa-font-solid);
  @include fa-pseudo(900, $content);
}

// Use for regular icons
@mixin fa-pseudo-regular($content) {
  font: var(--fa-font-regular);
  @include fa-pseudo(400, $content);
}

// Use for brand and social media icons
@mixin fa-pseudo-brand($content) {
  font: var(--fa-font-brands);
  @include fa-pseudo(400, $content);
}

// ----- Pulsar Container
$oh-bootstrap-container-xxl: map-get($container-max-widths, 'xxl') - 40px;
$oh-bootstrap-container-xl: map-get($container-max-widths, 'xl') - 40px;
$oh-bootstrap-container-lg: map-get($container-max-widths, 'lg') - 40px;

@function remtopx($rem) {
  @return calc($rem / 1rem) * 16px;
}

@mixin pulsar-container(
  $container-width-origin: $oh-bootstrap-container-xxl,
  $container-padding: remtopx($container-padding-x)
) {
  @include make-container($container-padding);
  $container-width: $container-width-origin + $container-padding;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      @if $container-width > $container-max-width {
        max-width: $container-max-width;
      } @else {
        max-width: $container-width;
      }
    }

    @include media-breakpoint-up(xxl) {
      max-width: $container-width;
    }
  }
}

@mixin oh-tiles-grid($column-count, $gap: 2rem) {
  display: grid;
  gap: $gap;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc($oh-bootstrap-container-lg/$column-count - $gap), 1fr)
  );

  @media (min-width: calc($oh-bootstrap-container-xl)) {
    grid-template-columns: repeat($column-count, 1fr);
  }

  @media (max-width: calc($oh-bootstrap-container-xxl/$column-count)) {
    grid-template-columns: 1fr;
  }
}
