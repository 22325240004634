.banner-disclaimer {
  background-color: $gray-100;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.26);
  padding: 4rem 0;

  .banner-disclaimer-inner {
    @include pulsar-container($oh-container-width-large);

    h2 {
      margin-bottom: 1.5rem;
    }

    p {
      @extend %oh-h5;

      font-weight: normal;

      a:not(.btn) {
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
}

.sponsors-category {
  margin-bottom: 3.5rem;
}

.category-title {
  text-align: center;
  margin-bottom: 1.25rem;
}

.sponsors-tiles {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -1rem;

  .sponsors-item-wrapper {
    flex: 0 1 25%;
    padding: 1rem;
  }

  .sponsors-item {
    width: 100%;
    border: 1px solid $gray-300;
    border-radius: 4px;
    overflow: hidden;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    aspect-ratio: 1 / 1;

    img {
      max-width: 100%;
      object-fit: contain;
    }
  }

  a.sponsors-item {
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
    }
  }
}

@include media-breakpoint-down(lg) {
  .sponsors-tiles {
    margin: 0 -0.75rem;

    .sponsors-item-wrapper {
      flex: 0 1 calc(100% / 3);
      padding: 0.75rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .sponsors-tiles {
    margin: 0 -0.5rem;

    .sponsors-item-wrapper {
      flex: 0 1 50%;
      padding: 0.5rem;
    }
  }

  .sponsors-category {
    margin-bottom: 2.5rem;
  }
}
