$gray-100: #f8f9fa !default;
$primary: #3d376a;
$navbar-padding-x: 0;
$navbar-padding-y: 0;
$font-family-base: 'Inter', sans-serif;
$border-radius: 4px;
$btn-border-radius-lg: 4px;
$card-border-width: 0;
$card-bg: $gray-100;
$card-spacer-x: 1.25rem;
$card-spacer-y: 1.25rem;
$grid-gutter-width: 2rem;
$accordion-button-active-bg: #dcf6f0;
$input-btn-padding-x: 1.25rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-font-size: 1rem;
$input-font-size-lg: 1.125rem;
$input-border-radius-lg: $border-radius;
$btn-font-weight: 500;
