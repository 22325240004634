// Custom global variables

// ----- container width and padding for pulsar container mixins
$oh-container-width-large: 1108px;
$oh-container-width-medium: 918px;
$oh-container-width-small: 728px;

// bootstrap variable overrides
@import 'bootstrap/variables';

// NOTE: HTML smooth scrolling messes with other functionality
// like anchor tags, form validation, tabbing through links, etc.
$enable-smooth-scroll: false;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

// Add a custom color to $theme-colors
$custom-colors: (
  'oh-teal': #50d3b4,
  'oh-white': #fff,
);
$theme-colors: map-merge($theme-colors, $custom-colors);

// ----- plyr vars
:root {
  --plyr-color-main: #{$primary};
}
