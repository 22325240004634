html.no-js {
  .vault-slider-section {
    .swiper {
      overflow: visible;

      .swiper-wrapper {
        @include oh-tiles-grid(3);
      }
    }

    .slider-button-next,
    .slider-button-prev {
      display: none;
    }
  }
}
